import React, { useContext, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { LoginForm } from "./LoginForm";
import { validationSchemaLogin } from "./validationSchemaLogin";
import { BaseRequest } from "../../../api/BaseRequest";
import { SessionContext } from "../../../contexts/SessionContext";
import { requestRepository } from "../../../api/RequestRepository";

export interface ILoginFields {
  login: string;
  password: string;
}

export const Login = () => {
  const initialValues: ILoginFields = {
    login: "",
    password: ""
  };
  const [sessionState, setSessionState] = useContext(SessionContext);
  const history = useHistory();

  useEffect(() => {
    if (sessionState.isAuthenticated) {
      history.push("/");
    }
  }, [sessionState, history, setSessionState]);

  return (
    <Formik
      validationSchema={validationSchemaLogin}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        requestRepository.authRequests
          .login({
            ...values
          })
          .then(res => {
            BaseRequest.setToken(res.token);
            setSessionState({ ...sessionState, isAuthenticated: true });

            if (res.redirectUrl === "") {
              history.push(sessionState.redirectPathOnAuthentication || "/");
            } else {
              window.location.replace(res.redirectUrl);
            }
          })
          .catch(() => setSubmitting(true));
      }}
    >
      {props => <LoginForm {...props} />}
    </Formik>
  );
};
