import avatar from "../../assets/images/avatar.png";
import logo from "../../assets/icons/logo.svg";
import iconTriangle from "../../assets/icons/triangle.svg";
import iconTriangleReverse from "../../assets/icons/triangleReverse.svg";
import iconSearch from "../../assets/icons/search.svg";
import iconNotifications from "../../assets/icons/notifications.svg";
import iconPuzzle from "../../assets/icons/eventsDesktop.svg";
import iconLogo from "../../assets/icons/logoMobile.svg";
import iconLines from "../../assets/icons/lines.svg";
import iconEvents from "../../assets/icons/events.svg";
import iconContacts from "../../assets/icons/contacts.svg";
import iconAgreements from "../../assets/icons/agreements.svg";
import iconHelp from "../../assets/icons/help.svg";
import iconNotificationMobile from "../../assets/icons/notificationMobile.svg";
import iconClose from "../../assets/icons/close.svg";
import iconPlus from "../../assets/icons/plus.svg";
import iconCalendar from "../../assets/icons/calendar.svg";
import iconCsv from "../../assets/icons/csv.svg";
import iconPlusGray from "../../assets/icons/plusGray.svg";
import iconChecked from "../../assets/icons/checked.svg";
import iconCheckedBox from "../../assets/icons/checkedBox.svg";
import iconUnCheckedBox from "../../assets/icons/unCheckedBox.svg";
import iconEmailClose from "../../assets/icons/emailClose.svg";
import iconDots from "../../assets/icons/dots.svg";
import iconDropDown from "../../assets/icons/dropdown.svg";
import iconPagePrev from "../../assets/icons/arrowLeft.svg";
import iconPageNext from "../../assets/icons/arrowRight.svg";
import iconHot from "../../assets/icons/hot.svg";
import iconSoon from "../../assets/icons/soon.svg";
import iconCross from "../../assets/icons/cross.svg";
import iconDot from "../../assets/icons/dot.svg";
import iconNotificationActive from "../../assets/icons/notificationActive.svg";
import eventLogo from "../../assets/images/eventLogo.png";
import divider from "../../assets/images/divider.png";
import contactAvatar from "../../assets/images/contact.png";
import noAvatar from "../../assets/images/no-avatar.png";
import iconEnvelop from "../../assets/icons/envelop.svg";
import iconEnvelopFull from "../../assets/icons/envelopFull.svg";
import iconAdd from "../../assets/icons/add.svg";
import iconEdit from "../../assets/icons/edit.svg";
import iconLocation from "../../assets/icons/location.svg";
import iconLogout from "../../assets/icons/logOut.svg";
import iconLogoutMobile from "../../assets/icons/logOutMobile.svg";
import iconInvitation from "../../assets/icons/invitation.svg";
import iconDelete from "../../assets/icons/delete.svg";
import iconContact from "../../assets/icons/contact.svg";
import iconStatusOk from "../../assets/icons/statusOk.svg";
import iconConfirm from "../../assets/icons/confirm.svg";

import agreement1 from "../../assets/images/agreement/1.png";
import agreement2 from "../../assets/images/agreement/2.png";
import fastEventLogo from "../../assets/images/fastEventLogo.png";

export const iconsPaths = {
  logo: {
    path: logo,
    alt: "Uconsent"
  },
  agreements: {
    agreement1,
    agreement2
  },
  divider,
  avatar,
  noAvatar,
  contactAvatar,
  iconTriangleReverse,
  iconSearch,
  iconNotifications,
  iconPuzzle,
  iconLogo,
  iconLines,
  iconEvents,
  iconContacts,
  iconAgreements,
  iconHelp,
  iconNotificationMobile,
  iconClose,
  iconPlus,
  iconCalendar,
  iconCsv,
  iconPlusGray,
  iconChecked,
  iconCheckedBox,
  iconUnCheckedBox,
  iconEmailClose,
  iconDots,
  iconDropDown,
  eventLogo,
  iconPagePrev,
  iconPageNext,
  iconHot,
  iconSoon,
  iconCross,
  iconNotificationActive,
  iconDot,
  iconEnvelop,
  iconEnvelopFull,
  iconAdd,
  iconEdit,
  iconLocation,
  iconLogout,
  iconLogoutMobile,
  iconInvitation,
  iconDelete,
  iconContact,
  iconStatusOk,
  iconTriangle,
  fastEventLogo,
  iconConfirm
};
