import React, { Suspense, lazy } from "react";
import styled from "styled-components";
import { SnackbarProvider } from "notistack";
import { MuiThemeProvider } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { Header } from "./components/header/Header";
import { theme } from "./core/Theme";
import { useTransitionEvent } from "./core/hooks/useWindowSize";
import { HeaderMobile } from "./components/header/HeaderMobile";
import { Authorization } from "./components/authorization/Authorization";
import {
  ProtectedRoute,
  ProtectedRouteProps
} from "./components/ProtectedRoute";
import { useSessionContext } from "./contexts/SessionContext";
import { routes } from "./core/routes";
import { ModalShow } from "./components/ModalShow";

const TermsAndConditionsPage = lazy(() => import("./components/static/TermsAndConditions"))

const DashBoardPage = lazy(() =>
  import("./components/event/list/DashBoardPage")
);

const FastInvitesPage = lazy(() =>
  import("./components/Invite/list/fast/FastInvitesPage")
);

const SendInvitePage = lazy(() =>
  import("./components/Invite/invite/SendInvitePage")
);

const CreateContactPage = lazy(() =>
  import("./components/contacts/create/CreateContactPage")
);

const FastInvitePage = lazy(() =>
  import("./components/Invite/create/fast/FastInvitePage")
);

const FastCreateEventPage = lazy(() =>
  import("./components/event/create/fast/FastCreateEventPage")
);

const CreateEventPage = lazy(() =>
  import("./components/event/create/default/CreateEventPage")
);
const EventPage = lazy(() => import("./components/event/view/EventPage"));

const Contacts = lazy(() => import("./components/contacts/list/Contacts"));
const InvitePage = lazy(() =>
  import("./components/Invite/create/default/InvitePage")
);
const InvitesPage = lazy(() =>
  import("./components/Invite/list/default/InvitesPage")
);

export const App = () => {
  const isDesktop = useTransitionEvent(767);
  const [sessionContext, updateSessionContext] = useSessionContext();

  const setRedirectPathOnAuthentication = (path: string) => {
    updateSessionContext({
      ...sessionContext,
      redirectPathOnAuthentication: path
    });
  };
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: !!sessionContext.isAuthenticated,
    authenticationPath: "/login",
    redirectPathOnAuthentication:
      sessionContext.redirectPathOnAuthentication || "",
    setRedirectPathOnAuthentication
  };

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <AppContainer>
          {isDesktop ? <Header /> : <HeaderMobile />}
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                redirectPathOnAuthentication="/"
                exact
                path="/"
                component={DashBoardPage}
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                exact
                path={routes.eventFastUpdate.path}
                component={FastCreateEventPage}
                redirectPathOnAuthentication="id url"
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.eventFastCreate.path}
                component={FastCreateEventPage}
                redirectPathOnAuthentication={routes.eventFastCreate.path}
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.eventCreate.path}
                component={CreateEventPage}
                redirectPathOnAuthentication={routes.eventCreate.path}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.eventFast.path}
                component={FastInvitesPage}
                redirectPathOnAuthentication="id url"
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.event.path}
                exact
                component={EventPage}
                redirectPathOnAuthentication="id url"
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                exact
                path={routes.eventUpdate.path}
                component={CreateEventPage}
                redirectPathOnAuthentication="id url"
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.contactCreate.path}
                component={CreateContactPage}
                redirectPathOnAuthentication={routes.contactCreate.path}
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.contactUpdate.path}
                component={CreateContactPage}
                redirectPathOnAuthentication="id url"
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.contacts.path}
                component={Contacts}
                redirectPathOnAuthentication={routes.contacts.path}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.invites.path}
                redirectPathOnAuthentication="id url"
                component={InvitesPage}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.inviteCreate.path}
                redirectPathOnAuthentication="id url"
                component={InvitePage}
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.inviteFastCreate.path}
                redirectPathOnAuthentication="id url"
                component={FastInvitePage}
              />

              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.sendInvite.path}
                redirectPathOnAuthentication="id url"
                component={() => <SendInvitePage isFast={false} />}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                path={routes.sendFastInvite.path}
                redirectPathOnAuthentication="id url"
                component={() => <SendInvitePage isFast />}
              />

              <Route path="/login" component={Authorization} />

              <Route path="/terms-and-conditions" component={TermsAndConditionsPage} />
            </Switch>
          </Suspense>
          <ModalShow />
        </AppContainer>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column;
`;
