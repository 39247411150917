import React from "react";

export const ActiveCheckBox = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="16"
      height="16"
      rx="4"
      fill="white"
      stroke="#7FB1FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5266 12.6351C8.07322 13.1216 7.33756 13.1216 6.8844 12.6351L4.34004 9.90491C3.88665 9.41864 3.88665 8.62926 4.34004 8.14299C4.7932 7.65649 5.52885 7.65649 5.98223 8.14299L7.49817 9.76943C7.61261 9.89199 7.79839 9.89199 7.91304 9.76943L12.0178 5.36487C12.4709 4.87838 13.2066 4.87838 13.66 5.36487C13.8777 5.5985 14 5.91547 14 6.24583C14 6.57619 13.8777 6.89317 13.66 7.12679L8.5266 12.6351Z"
      fill="#2E71D8"
    />
  </svg>
);
