import { Backdrop, Modal, ModalProps } from "@material-ui/core";
import React, { FC } from "react";

export interface IStyledModalProps extends Omit<ModalProps, "children"> {
  onClose?: () => void;
  customStyles: () => Record<"modal", string>;
}

export const StyledModal: FC<IStyledModalProps> = ({
  children,
  open,
  onClose,
  customStyles
}) => {
  const classes = {
    modal: customStyles()
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <>{children}</>
    </Modal>
  );
};
