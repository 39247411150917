import React, { FC } from "react";
import styled from "styled-components";
import {
  ConfirmationModal,
  IConfirmationModalProps
} from "./ConfirmationModal";

interface IProps extends IConfirmationModalProps {
  description?: string;
}

export const LogOutModal: FC<IProps> = ({ children, description, ...rest }) => {
  return (
    <ConfirmationModal {...rest}>
      <DescriptionText>{description}</DescriptionText>
      {children}
    </ConfirmationModal>
  );
};

const DescriptionText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252733;
  margin-left: 5px;
  margin-right: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
