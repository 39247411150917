import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { iconsPaths } from "../../core/paths/Icons";
import { IDropList } from "../../core/Text";

export const DropList: React.FC<IDropList> = ({
  listName,
  links,
  bottomItem
}) => {
  const LinksArray = useMemo(
    () =>
      links.map(item => (
        <MyListItem key={item.label} to={item.link}>
          {item.label}
        </MyListItem>
      )),
    [links]
  );

  return (
    <Block>
      <BlockWithTriangle>
        <MyLabel>{listName}</MyLabel>
        <Triangle src={iconsPaths.iconTriangleReverse} />
      </BlockWithTriangle>

      <MyList>
        <MainItems>{LinksArray}</MainItems>
        <BoldLabel to={bottomItem.link}>{bottomItem.label}</BoldLabel>
      </MyList>
    </Block>
  );
};

const Block = styled.div`
  display: flex;
  > ul {
    display: block;
    visibility: hidden;
    opacity: 0;
  }

  :hover {
    > ul {
      transition: 1.05s;
      visibility: visible;
      opacity: 1;
    }
  }
`;

const BlockWithTriangle = styled.div`
  display: flex;
  align-items: center;
`;

const Triangle = styled.img``;

const MyLabel = styled.p`
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #26363e;
  opacity: 0.7;
  padding-right: 5px;
`;

const MyList = styled.ul`
  margin-left: -120px;
  margin-top: 265px;
  padding: 20px 15px;
  display: none;
  width: 210px;
  z-index: 2;

  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 2px 2px;
`;
const MainItems = styled.div`
  flex-flow: column;
  display: flex;
  padding-bottom: 13px;
  border-bottom: 1px solid #dfe0eb;
  margin-bottom: 13px;

  > *:not(*:last-child) {
    margin-bottom: 12px;
  }
`;

const MyListItem = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #252733;
  text-decoration: none !important;
`;

const BoldLabel = styled(MyListItem)`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
`;
