import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { BootstrapContainer } from "../../ui/BootstrapContainer";
import { Login } from "./login/Login";
import { SignUp } from "./singUp/SignUp";
import { iconsPaths } from "../../core/paths/Icons";
import { useTransitionEvent } from "../../core/hooks/useWindowSize";
import { ButtonPrimary } from "../../ui/MyComponents";
import { makeStyles } from "@material-ui/core/styles";

export const Authorization = () => {
  const isDesktop = useTransitionEvent(599);
  const [signUpPage, setSignUpPage] = useState(false);
  const classes = makeStyles(ButtonPrimary)();
  const changeWindow = () => {
    setSignUpPage(true);
  };

  const desktop = (
    <BootstrapContainer>
      <MainContainer>
        <Title>Welcome to UConcent</Title>
        <MainBlock>
          <Login />
          <Divider src={iconsPaths.divider} alt="" />
          <SignUp />
        </MainBlock>
      </MainContainer>
    </BootstrapContainer>
  );

  const mobile = (
    <>
      <MobileTitle>
        {signUpPage ? "Simple registration" : "Welcome to UConcent"}
      </MobileTitle>
      <BootstrapContainer paddingMobile>
        {signUpPage ? (
          <SignUp />
        ) : (
          <LoginMobileBlock>
            <Login />
            <GrayLabel>Don&apos;t have an account?</GrayLabel>
            <Button
              fullWidth
              classes={classes}
              type="button"
              onClick={changeWindow}
              variant="contained"
              color="primary"
            >
              Sign up
            </Button>
          </LoginMobileBlock>
        )}
      </BootstrapContainer>
    </>
  );
  return <>{isDesktop ? desktop : mobile}</>;
};

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const Title = styled.h1`
  margin: 50px 0;
`;
const MobileTitle = styled.h1`
  width: 100%;
  padding-bottom: 20px;
  padding-left: 25px;
  border-bottom: 1.5px solid #dfe0eb;
`;

const Divider = styled.img`
  padding: 0 40px;
`;

const MainBlock = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;

const GrayLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #a6a9af;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 20px;
`;

const LoginMobileBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: 100px;
`;
