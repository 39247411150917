import { BaseRequest } from "../BaseRequest";
import { IEvent } from "../dto/Event";
import { IEventCreate } from "../dto/EventCreate";
import { IInvitationsList } from "../dto/InvitationsList";
import { IInvitationsInfo } from "../dto/InvitationsInfo";
import { IEventUpdate } from "../dto/EventUpdate";
import { InvitationByEvent } from "../dto/InvitationByEvent";
import { IConfirmList } from "../dto/ConfirmList";
import {IEventInfoForFastEvent} from "../dto/EventInfoForFastEvent";

export class EventRequests extends BaseRequest {
  getAll(): Promise<IEventInfoForFastEvent[]> {
    return this.fetch(`/api/Event/GetAllEvents`, {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  getById(id: string | number): Promise<IEvent[]> {
    return this.fetch(`/api/Event/GetEventById?id=${id}`, {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  remove(id: string | number): Promise<void> {
    return this.fetch(`/api/Event/Archive?id=${id}`, {
      method: "POST"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  create(event: IEventCreate): Promise<{ eventId: number }> {
    return this.fetch("/api/Event/CreateEvent", {
      method: "POST",
      body: JSON.stringify(event)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  update(
    event: IEventUpdate
  ): Promise<{ eventId: number; templateAgreementId?: number }> {
    return this.fetch(`/api/Event/UpdateEvent`, {
      method: "POST",
      body: JSON.stringify(event)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  createInvite(invList: IInvitationsList): Promise<void> {
    return this.fetch(`/api/Event/CreateInvite`, {
      method: "POST",
      body: JSON.stringify(invList)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  toConfirmInvitations(confirmList: IConfirmList): Promise<void> {
    return this.fetch(`/api/Event/ToConfirmInvitations`, {
      method: "POST",
      body: JSON.stringify(confirmList)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  getInvitationsByEventId(id: number | string): Promise<IInvitationsInfo> {
    return this.fetch(`/api/Event/GetInvitationsByEventId?eventId=${id}`, {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  getEventWithInvitationsByEventId(
    id: number | string
  ): Promise<InvitationByEvent> {
    return this.fetch(
      `/api/Event/GetEventWithInvitationsByEventId?eventId=${id}`,
      {
        method: "GET"
      }
    )
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  removeInvitation(listInfo: IInvitationsList): Promise<void> {
    return this.fetch(`/api/Event/InvitationArchive`, {
      method: "POST",
      body: JSON.stringify(listInfo)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }
}
