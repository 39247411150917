import { useCallback, useEffect, useState } from "react";

export const useWindowSize = (): {
  width: number | undefined;
  height: number | undefined;
} => {
  const isClient = typeof window === "object";

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }

    function handleResize(): void {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [getSize, isClient]);

  return windowSize;
};

export const useTransitionEvent = (breakpoint = 1024): boolean => {
  const [transition, setTransition] = useState(true);
  const windowSize = useWindowSize();

  useEffect(() => {
    const result = (windowSize.width || 0) > breakpoint;
    if (result !== transition) setTransition(result);
  }, [breakpoint, transition, windowSize]);

  return transition;
};

export const useIsTablet = (): boolean => {
  const [transition, setTransition] = useState(true);
  const windowSize = useWindowSize();

  useEffect(() => {
    const result =
      (windowSize.width || 0) > 767 && (windowSize.width || 0) < 992;
    if (result !== transition) setTransition(result);
  }, [transition, windowSize]);

  return transition;
};
