import styled from "styled-components";

interface IProps {
  column?: boolean;
  paddingMobile?: boolean;
  grow?:boolean;
}
export const BootstrapContainer = styled.div<IProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
  flex-grow: ${({grow}) => grow ? "1": "unset"};
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;

  @media (min-width: 544px) {
    max-width: 576px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 940px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media screen and (max-width: 543px) {
    padding: ${({ paddingMobile }) => (paddingMobile ? "0 25px" : 0)};
  }

  flex-direction: ${({ column }) => (column ? "column" : "row")};
`;
