import { AuthRequests } from "./requests/AuthRequests";
import { EventRequests } from "./requests/EventRequests";
import { GeoRequests } from "./requests/GeoRequests";
import { ContactRequests } from "./requests/ContactRequests";
import { TemplateAgreementRequests } from "./requests/TemplateAgreementRequests";
import { EventTypeRequests } from "./requests/EventTypeRequests";
import { SignRequests } from "./requests/SignRequests";

class RequestRepository {
  authRequests = new AuthRequests();

  eventRequests = new EventRequests();

  geoRequests = new GeoRequests();

  contactRequests = new ContactRequests();

  templateAgreementRequests = new TemplateAgreementRequests();

  eventTypeRequests = new EventTypeRequests();

  signRequests = new SignRequests();
}

export const requestRepository = new RequestRepository();
