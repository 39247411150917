import React, { FC, useContext } from "react";
import { StyledModal } from "./StyledModal";
import { Button, Fade } from "@material-ui/core";
import styled from "styled-components";
import { iconsPaths } from "../../core/paths/Icons";
import { ContactItem } from "../inviteBlock/ContactItem";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonPrimary, ButtonWhiteBlueBordered } from "../MyComponents";
import { SelectedContactsContext } from "../../contexts/SelectedContactsContext";
import { IContact } from "../../api/dto/Contact";
import { customModal } from "../styles/customModal";

interface IProps {
  title: string;
  description: string;
  acceptText?: string;
  closeText?: string;
  onAccept: () => void;
  onClose: () => void;
  closeHandler: () => void;
}

export const SuccessModal: FC<IProps> = ({
  onAccept,
  onClose,
  title,
  acceptText,
  closeText,
  description,
  closeHandler
}) => {
  const classes = {
    ButtonWhiteBlueBordered: makeStyles(ButtonWhiteBlueBordered)(),
    primaryButton: makeStyles(ButtonPrimary)()
  };

  const [selectedContacts, setSelectedContacts] = useContext(
    SelectedContactsContext
  );

  const checkIsSelected = (id: number) => {
    return selectedContacts.findIndex(i => i.id === id) !== -1;
  };

  const changeContactStatus = (contact: IContact) => {
    const searchIndex = selectedContacts.findIndex(i => i.id === contact.id);

    if (searchIndex === -1) {
      setSelectedContacts([...selectedContacts, contact]);
    } else {
      setSelectedContacts(
        selectedContacts.filter(
          item => item.id !== selectedContacts[searchIndex].id
        )
      );
    }
  };

  return (
    <StyledModal open={true} customStyles={customModal}>
      <Fade in={true}>
        <MainContainer>
          <CrossBlock onClick={closeHandler}>
            <CrossImage src={iconsPaths.iconCross} alt="" />
          </CrossBlock>
          <HeaderBlock>
            <HeaderRow>
              <TitleBlock>
                <Title>Done! </Title>
                <Title>{title}</Title>
              </TitleBlock>
              <StatusIcon src={iconsPaths.iconStatusOk} alt="Ok" />
            </HeaderRow>
            <Description>{description}</Description>
          </HeaderBlock>

          {selectedContacts.length > 0 && (
            <BodyBlock>
              <ContactsBlock>
                {selectedContacts.map(contact => (
                  <ContactItem
                    {...contact}
                    key={contact.id}
                    isChecked={checkIsSelected(contact.id)}
                    changeContactStatus={changeContactStatus}
                  />
                ))}
              </ContactsBlock>
            </BodyBlock>
          )}

          <ButtonBlock>
            <Button
              classes={classes.ButtonWhiteBlueBordered}
              fullWidth
              variant="outlined"
              onClick={onClose}
            >
              {closeText ? closeText : "Go to Main Screen"}
            </Button>

            <Button
              classes={classes.primaryButton}
              fullWidth
              variant="contained"
              color="primary"
              onClick={onAccept}
            >
              {acceptText ? acceptText : "Go to Event"}
            </Button>
          </ButtonBlock>
        </MainContainer>
      </Fade>
    </StyledModal>
  );
};

const MainContainer = styled.div`
  width: 480px;
  display: flex;
  flex-flow: column;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px 20px 40px 40px;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: calc(100vh - 84px);
  }
`;

const CrossBlock = styled.div`
  display: flex;
`;

const CrossImage = styled.img`
  cursor: pointer;
  margin-left: auto;
  width: 12px;
  height: 12px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const HeaderBlock = styled.div`
  width: 400px;
  display: flex;
  flex-flow: column;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
`;
const TitleBlock = styled.div``;

const StatusIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 15px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Description = styled.p`
  max-width: 250px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  color: #a6a9af;
`;

const BodyBlock = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

const ContactsBlock = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 25px;
  max-height: calc(100vh - 412px);
  padding: 0 20px;
  border: 1px solid #c2c7cd;
  box-sizing: border-box;
  border-radius: 4px;

  & > :last-child {
    border-bottom: unset;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;

  & > :first-child {
    width: 190px;
  }

  & > :nth-child(2) {
    width: 190px;
  }

  @media screen and (max-width: 767px) {
    flex-flow: column;
    flex-grow: 1;
    & > :first-child {
      width: 100%;
      margin-top: auto;
    }

    & > :nth-child(2) {
      margin-top: 15px;
      width: 100%;
    }
  }
`;
