import { ILoginResult } from "../dto/LoginResult";
import { BaseRequest } from "../BaseRequest";
import { ILogin } from "../dto/Login";
import { IRegistration } from "../dto/Register";

export class AuthRequests extends BaseRequest {
  login(authentication: ILogin): Promise<ILoginResult> {
    return this.fetch(`/api/Auth/Login`, {
      method: "POST",
      body: JSON.stringify(authentication)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  register(data: IRegistration): Promise<ILoginResult> {
    return this.fetch("/api/Auth/Register", {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }
}
