import React, { useRef, useState } from "react";
import styled from "styled-components";
import { iconsPaths } from "../../core/paths/Icons";
import { useOnClickOutside } from "../../core/hooks/useOnClickOutside";
import { FakeNotifications } from "../../api/FakeData";
import { timeDifferent } from "../../core/utils/TimeDifferent";

export interface INotification {
  id: number;
  image: string;
  name: string;
  description: string;
  date: string;
}

export const NotificationBlock = () => {
  const myRef = useRef<HTMLDivElement>(null);
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const displayHandler = () => {
    setDisplayNotifications(!displayNotifications);
  };

  useOnClickOutside(myRef, () => setDisplayNotifications(false));

  return (
    <NotificationsContainer ref={myRef}>
      {displayNotifications ? (
        <Icon
          src={iconsPaths.iconNotificationActive}
          alt=""
          onClick={displayHandler}
        />
      ) : (
        <Icon
          alt=""
          src={iconsPaths.iconNotifications}
          onClick={displayHandler}
        />
      )}
      <MainContainer isVisible={displayNotifications}>
        <HeaderLabel>Your Notification</HeaderLabel>
        <NotificationBody>
          {FakeNotifications.map(notification => (
            <ItemBlock key={notification.id}>
              <ItemImage src={notification.image} alt={notification.name} />
              <ItemNameBlock>
                <ItemTopBlock>
                  <ItemName>{notification.name}</ItemName>
                  <DotBlock>
                    <GrayDot src={iconsPaths.iconDot} alt="" />
                    <GrayText>{timeDifferent(notification.date)}</GrayText>
                  </DotBlock>
                </ItemTopBlock>
                <GrayText>{notification.description}</GrayText>
              </ItemNameBlock>
            </ItemBlock>
          ))}
        </NotificationBody>
      </MainContainer>
    </NotificationsContainer>
  );
};

const NotificationsContainer = styled.div`
  position: relative;
  padding-right: 25px;
`;

const NotificationBody = styled.div`
  max-height: 180px;
  overflow-y: auto;
  padding: 0 20px;

  > :first-child {
    padding-top: 20px;
  }
  > :last-child {
    padding-bottom: 20px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(194, 194, 194, 0.2);
    border-radius: 5px;
    scrollbar-color: rgba(194, 194, 194, 0.2);
  }
`;

const DotBlock = styled.div`
  display: flex;
  align-items: center;
`;

const MainContainer = styled.div<{ isVisible: boolean }>`
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  opacity: ${props => (props.isVisible ? "1" : "0")};

  width: 267px;
  display: flex;
  flex-flow: column;
  position: absolute;
  right: 0;
  top: 37px;
  z-index: 1;

  background: #ffffff;
  border: 0.5px solid #dfe0eb;
  box-sizing: border-box;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const HeaderLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #a6a9af;

  padding: 11px 0 12px 21px;
  border-bottom: 1px solid #dfe0eb;
  margin-right: 0;
`;

const ItemBlock = styled.div`
  display: flex;
  padding-top: 15px;
`;
const ItemImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
`;

const ItemNameBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const ItemTopBlock = styled.div`
  display: flex;
`;

const ItemName = styled.span`
  max-width: 100px;
  height: min-content;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #252733;
`;

const GrayText = styled.span`
  white-space: nowrap;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: #a6a9af;
`;

const GrayDot = styled.img`
  margin: 0 7px;
`;
