import React from "react";
import { createContext, FC, useState } from "react";
import { IContact } from "../api/dto/Contact";

 export const ContactsContext = createContext<
  [IContact[], (arr: IContact[]) => void]
>([[] as IContact[], () => {}]);



export const ContactsContextProvider: FC = props => {
  const { children } = props;
  const [userContacts, setUserContacts] = useState<IContact[]>([]);

  return (
    <ContactsContext.Provider value={[userContacts, setUserContacts]}>
      {children}
    </ContactsContext.Provider>
  );
};
