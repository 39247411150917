import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
  redirectPathOnAuthentication: string;
  setRedirectPathOnAuthentication: (path: string) => void;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const { redirectPathOnAuthentication, isAuthenticated } = props;
  const currentLocation = useLocation();

  let redirectPath = redirectPathOnAuthentication;

  if (redirectPath === "id url") {
    redirectPath = currentLocation.pathname;
  }

  if (!isAuthenticated) {
    props.setRedirectPathOnAuthentication(currentLocation.pathname);
    redirectPath = props.authenticationPath;
  }

  if (redirectPath !== currentLocation.pathname) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
