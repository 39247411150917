import React from "react";
import { createContext, FC, useContext, useState } from "react";
import { IEventCreate } from "../api/dto/EventCreate";

export const EventCreateContext = createContext<
  [IEventCreate, (event: IEventCreate) => void]
>([{} as IEventCreate, () => {}]);

export const useEventCreateContext = () => useContext(EventCreateContext);

export const EventCreateContextProvider: FC = props => {
  const { children } = props;
  const [eventCreateState, setEventCreateState] = useState<IEventCreate>(
    {} as IEventCreate
  );

  const defaultSessionContext: [IEventCreate, typeof setEventCreateState] = [
    eventCreateState,
    setEventCreateState
  ];

  return (
    <EventCreateContext.Provider value={defaultSessionContext}>
      {children}
    </EventCreateContext.Provider>
  );
};
