import React, { FC } from "react";
import styled from "styled-components";
import { iconsPaths } from "../../core/paths/Icons";
import { IContact } from "../../api/dto/Contact";

interface IProps extends IContact {
  isChecked: boolean;
  changeContactStatus: (contact: IContact) => void;
  invitation?: boolean
}

export const ContactItem: FC<IProps> = contact => {
  const {
    name,
    email,
    imagePath,
    id,
    isChecked,
    changeContactStatus
  } = contact;
  return (
    <Container key={id}>
      <MainBlock onClick={() => changeContactStatus(contact)}>
        <LeftBlock>
          <ContactPhoto src={imagePath || iconsPaths.noAvatar} alt={name} />
          <ContactInfo>
            <ContactName>{name}</ContactName>
            <ContactEmail>{email}</ContactEmail>
          </ContactInfo>
        </LeftBlock>
        <RightBlock>

          <StatusImage
            src={isChecked ? iconsPaths.iconChecked : iconsPaths.iconPlusGray}
          />
        </RightBlock>
      </MainBlock>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
    border-bottom: 1px solid #c2c7cd;

`;

const MainBlock = styled.div`
  height: 84px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

const ContactPhoto = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
  user-select: none;
`;

const ContactInfo = styled.div``;

const ContactName = styled.p`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252733;

  @media screen and (max-width: 600px) {
    max-width: 150px;
  }
`;

const ContactEmail = styled.p`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #c5c7cd;
  margin-top: 2px;

  @media screen and (max-width: 600px) {
    max-width: 150px;
  }
`;

const RightBlock = styled.div``;
const StatusImage = styled.img`
  user-select: none;
`;
