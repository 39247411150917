import { BaseRequest } from "../BaseRequest";
import { IContactUpdate } from "../dto/ContactUpdate";
import { IContactCreate } from "../dto/ContactCreate";
import { IContact } from "../dto/Contact";

export class ContactRequests extends BaseRequest {
  create(contact: IContactCreate): Promise<void> {
    return this.fetch("/api/Contact/Add", {
      method: "POST",
      body: JSON.stringify(contact)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  update(contact: IContactUpdate): Promise<void> {
    return this.fetch("/api/Contact/Update", {
      method: "POST",
      body: JSON.stringify(contact)
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  getById(id: number): Promise<IContact[]> {
    return this.fetch(`/api/Contact/FindContactById?contactId=${id}`, {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  getContacts(): Promise<IContact[]> {
    return this.fetch("/api/Contact/GetContacts", { method: "GET" })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  remove(ids: number[]): Promise<void> {
    return this.fetch("/api/Contact/Archive", {
      method: "POST",
      body: JSON.stringify(ids)
    }).catch(BaseRequest.handleError);
  }
}
