export const ButtonPrimary = {
  root: {
    height: 52,
    borderRadius: "4px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#327CED",
      boxShadow: "none"
    },
    "&:disabled": {
      boxShadow: "none",
      backgroundColor: "#F7FCFF",
      color: "#B6DEFF",
      border: "1px solid #B6DEFF"
    },
    "&:focus": {
      boxShadow: "none",
      backgroundColor: "#F3F8FF",
      color: "#347EDE",
      border: "1px solid #7FB1FF"
    }
  }
};

export const ButtonWhite = {
  root: {
    height: 52,
    color: "#657281",
    border: "1px solid #C2C7CD",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    boxShadow: "none",

    "&:hover": {
      color: "#657281",
      backgroundColor: "#F8F8F8",
      border: "1px solid #B0B4B9",
      boxShadow: "none"
    },
    "&:disabled": {
      backgroundColor: "#F8F8F8",
      color: "#657281",
      border: "1px solid #B0B4B9",
      boxShadow: "none"
    },
    "&:focus": {
      color: "657281",
      backgroundColor: "#F8F8F8",
      border: "1px solid #7FB1FF",
      boxShadow: "none"
    }
  }
};


export const ButtonWhiteBorderBlue = {
  root: {
    height: 52,
    color: "#657281",
    border: "1px solid #347EDE",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    boxShadow: "none",

    "&:hover": {
      color: "#2E71D8",
      backgroundColor: "#F7FCFF",
      border: "1px solid #B6DEFF",
      boxShadow: "none"
    },
    "&:disabled": {
      backgroundColor: "#f7fcff",
      color: "rgba(43,113,217,0.5)",
      border: "1px solid #B6DEFF",
      boxShadow: "none"
    },
    "&:focus": {
      color: "#2E71D8",
      backgroundColor: "#F3F8FF",
      border: "2px solid #7FB1FF",
      boxShadow: "none"
    }
  }
};

export const ButtonWhiteBlueIcon = {
  root: {
    height: 50,
    color: "#2E71D8",
    borderColor: "#2E71D8",
    borderRadius: "4px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#F7FCFF",
      border: "1px solid #B6DEFF"
    },
    "&:disabled": {
      backgroundColor: "#F7FCFF",
      color: "#B6DEFF",
      border: "1px solid #B6DEFF"
    },
    "&:focus": {
      backgroundColor: "#F1FAFF",
      border: "1px solid #2C6BCC"
    }
  }
};

export const InputPrimary = {
  root: {
    fontWeight: 600,
    fontSize: "14px !important",
    lineHeight: "18px",
    borderRadius: "4px",
    minHeight: 50,
    borderWidth: 1
  }
};

export const ButtonBlue = {
  root: {
    height: 52,
    borderRadius: "4px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#327CED",
      boxShadow: "none"
    },
    "&:disabled": {
      boxShadow: "none",
      backgroundColor: "#F7FCFF",
      color: "#B6DEFF",
      border: "1px solid #B6DEFF"
    },
    "&:focus": {
      boxShadow: "none",
      backgroundColor: "#F3F8FF",
      color: "#347EDE",
      border: "1px solid #7FB1FF"
    }
  }
};

export const ButtonWhiteBlueBordered = {
  root: {
    color: "#2E71D8",
    borderColor: "#2E71D8",
    borderRadius: "4px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: 700,
    border: `1px solid #347EDE`,
    "&:hover": {
      backgroundColor: "#F7FCFF",
      border: "1px solid #B6DEFF"
    },
    "&:disabled": {
      backgroundColor: "#F7FCFF",
      color: "#B6DEFF",
      border: "1px solid #B6DEFF"
    },
    "&:focus": {
      backgroundColor: "#F1FAFF",
      border: "1px solid #2C6BCC"
    }
  }
};

export const ButtonWhiteGrayBordered = {
  root: {
    height: 35,
    color: "#657281",
    borderRadius: "4px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: 700,
    border: `1px solid #C2C7CD`,
    "&:hover": {
      backgroundColor: "#F7FCFF",
      border: "1px solid #B6DEFF"
    },
    "&:disabled": {
      backgroundColor: "#F7FCFF",
      color: "#B6DEFF",
      border: "1px solid #B6DEFF"
    },
    "&:focus": {
      backgroundColor: "#F1FAFF",
      border: "1px solid #2C6BCC"
    }
  }
};
