import React, { useContext } from "react";
import { IModal, ModalContext } from "../contexts/ModalContext";
import { SuccessModal } from "../ui/modal/SuccessModal";
import { useHistory } from "react-router";
import { routes } from "../core/routes";

export enum ModalTypesEnum {
  default,
  invitations = "invitations",
  fastInvitations = "fastInvitations",
  event = "event",
  fastEvent = "fastEvent"
}

export const ModalShow = () => {
  const router = useHistory();
  const [modalContext, setModalContext] = useContext(ModalContext);

  const closeModalHandler = () => {
    setModalContext({} as IModal);
  };

  const onModalAccept = () => {
    router.push(routes.event.link({ id: modalContext.id }));
    closeModalHandler();
  };

  const onFastCreate = () => {
    router.push(routes.eventFast.link({ id: modalContext.id }));
    closeModalHandler();
  };

  const onFastInvite = () => {
    router.push(routes.eventFast.link({ id: modalContext.id }));
    closeModalHandler();
  };

  const onModalClose = () => {
    router.push("/");
    closeModalHandler();
  };

  const modalType = () => {
    switch (modalContext.type) {
      case ModalTypesEnum.invitations: {
        return (
          <SuccessModal
            title={modalContext.title}
            description={modalContext.description}
            closeHandler={closeModalHandler}
            onAccept={onModalAccept}
            onClose={onModalClose}
          />
        );
      }

      case ModalTypesEnum.fastInvitations: {
        return (
          <SuccessModal
            title={modalContext.title}
            description={modalContext.description}
            closeHandler={closeModalHandler}
            onAccept={onFastInvite}
            onClose={onModalClose}
          />
        );
      }

      case ModalTypesEnum.event: {
        return (
          <SuccessModal
            title={modalContext.title}
            description={modalContext.description}
            closeHandler={closeModalHandler}
            onAccept={onModalAccept}
            onClose={onModalClose}
          />
        );
      }

      case ModalTypesEnum.fastEvent: {
        return (
          <SuccessModal
            title={modalContext.title}
            description={modalContext.description}
            closeHandler={closeModalHandler}
            onAccept={onFastCreate}
            onClose={onModalClose}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  return modalType();
};
