export interface IRoute {
  path: string;
  link: (
    params: {
      [key: string]: string | number;
    } & { id?: number | string }
  ) => string;
}

export const routes: Record<IRoutesType, IRoute> = {
  login: { path: "/login", link: () => "/login" },
  event: {
    path: "/event/:id",
    link: ({ id }) => `/event/${id}`
  },
  eventFast: { path: "/event/fast/:id", link: ({ id }) => `/event/fast/${id}` },
  eventCreate: { path: "/event/create", link: () => "/event/create" },
  eventFastCreate: {
    path: "/event/fastCreate",
    link: () => "/event/fastCreate"
  },
  eventUpdate: {
    path: "/event/update/:id",
    link: ({ id }) => `/event/update/${id}`
  },
  eventFastUpdate: {
    path: "/event/fastCreate/:id",
    link: ({ id }) => `/event/fastCreate/${id}`
  },
  contacts: { path: "/contacts", link: () => "/contacts" },
  contactCreate: { path: "/contacts/create", link: () => "/contacts/create" },
  contactUpdate: {
    path: "/contacts/update/:id",
    link: ({ id }) => `/contacts/update/${id}`
  },
  invites: { path: "/invites/:id", link: ({ id }) => `/invites/${id}` },
  invite: {
    path: "/invite/:id",
    link: ({ id }) => `/invite/${id}`
  },
  inviteCreate: {
    path: "/invite/create",
    link: () => `/invite/create`
  },
  inviteFastCreate: {
    path: "/invite/fastCreate",
    link: () => `/invite/fastCreate`
  },
  sendInvite: {
    path: "/invite/send/:id",
    link: ({ id }) => `/invite/send/${id}`
  },
  sendFastInvite: {
    path: "/invite/fast-send/:id",
    link: ({ id }) => `/invite/fast-send/${id}`
  }
};

export type IRoutesType =
  | "login"
  | "event"
  | "eventCreate"
  | "eventFastCreate"
  | "eventUpdate"
  | "eventFastUpdate"
  | "contacts"
  | "contactCreate"
  | "contactUpdate"
  | "invites"
  | "eventFast"
  | "invite"
  | "inviteCreate"
  | "inviteFastCreate"
  | "sendInvite"
  | "sendFastInvite";
