import { differenceInMinutes, format, toDate } from "date-fns";

export const timeDifferent = (comparingTime: string) => {
  const differentMinutes = differenceInMinutes(
    Date.now(),
    toDate(Number(comparingTime))
  );
  let returnValue = format(toDate(Number(comparingTime)), "dd.mm.yyyy");

  if (differentMinutes < 1) {
    returnValue = "just now";
  } else if (differentMinutes < 60) {
    returnValue = `${differentMinutes} min ago`;
  } else if (differentMinutes < 1440) {
    returnValue = `${Math.ceil(differentMinutes / 60)} hours ago`;
  } else if (differentMinutes < 10080) {
    returnValue = `${Math.ceil(differentMinutes / 1440)} days ago`;
  }

  return returnValue;
};
