import { assertNotNull } from "../core/utils/assertNotNull";

export class BaseRequest {
  protected baseUrl: string = "https://api.uconsent.app";
  static handleError = async (err: any): Promise<any> => {
    const error = await err;
    return Promise.reject(error);
  };

  static setToken = (token: string) => {
    localStorage.setItem("token", token);
  };

  fetch(url: string, config: Record<string, any>): Promise<any> {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : ""
    };
    return fetch(this.baseUrl + url, { headers, ...config }).then(response => {
      if (!response.status || response.status < 200 || response.status >= 300) {
        throw response;
      }

      return response;
    });
  }

  q(params: Record<string, any>): string {
    const query = Object.keys(params)
      .filter(k => params[k] != null)
      .map(
        k => `${k}=${encodeURIComponent(assertNotNull(params[k]).toString())}`
      )
      .join("&");

    return query ? `?${query}` : "";
  }
}
