import React, {createContext, FC, useState} from "react";
import {IContact} from "../api/dto/Contact";

export const SelectedContactsContext = createContext<
  [IContact[], (arr: IContact[]) => void]
  >([[] as IContact[], () => {}]);

export const SelectedContactsContextProvider: FC = props => {
  const { children } = props;
  const [selectedContacts, setSelectedContacts] = useState<IContact[]>([]);

  return (
    <SelectedContactsContext.Provider value={[selectedContacts, setSelectedContacts]}>
      {children}
    </SelectedContactsContext.Provider>
  );
};