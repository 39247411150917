import React, { useContext } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { BaseRequest } from "../../../api/BaseRequest";
import { validationSchemaSignUp } from "./validationSchemaSignUp";
import { SignUpForm } from "./SignUpForm";
import { SessionContext } from "../../../contexts/SessionContext";
import { requestRepository } from "../../../api/RequestRepository";

export interface ISignUpFields {
  login: string;
  password: string;
  confirmPassword: string;
}

export const SignUp = () => {
  const initialValues: ISignUpFields = {
    login: "",
    password: "",
    confirmPassword: ""
  };

  const snackbar = useSnackbar();
  const [sessionState, setSessionState] = useContext(SessionContext);
  const history = useHistory();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaSignUp}
      onSubmit={({ login, password }, actions) => {
        actions.setSubmitting(false);

        requestRepository.authRequests
          .register({ login, password })
          .then(response => {
            BaseRequest.setToken(response.token);
            setSessionState({ ...sessionState, isAuthenticated: true });

            if (response.redirectUrl === "") {
              history.push(sessionState.redirectPathOnAuthentication || "/");
            } else {
              window.location.replace(response.redirectUrl);
            }
          })
          .catch(() => {
            snackbar.enqueueSnackbar("ERR");
          });
      }}
    >
      {props => <SignUpForm {...props} />}
    </Formik>
  );
};
