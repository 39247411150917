import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BootstrapContainer } from "../../ui/BootstrapContainer";
import { iconsPaths } from "../../core/paths/Icons";
import { appTexts } from "../../core/Text";
import { DropList } from "./DropList";
import { NotificationBlock } from "./NotificationBlock";
import { LogOutModal } from "../../ui/modal/LogOutModal";
import { useSessionContext } from "../../contexts/SessionContext";
import {defaultModal} from "../../ui/styles/defaultModal";

export const Header = () => {
  const [logOutModal, setLogOutModal] = useState(false);
  const [sessionContext, updateSessionContext] = useSessionContext();

  const visibleLogOut = (val = true) => {
    setLogOutModal(val);
  };

  const logOutHandler = () => {
    localStorage.clear();
    visibleLogOut(false);
    updateSessionContext({ ...sessionContext, isAuthenticated: false });
  };


  return (
    <HeaderContainer>
      <MainContainer>
        <NavBar>
          <LeftBlock>
            <LogoLink to={appTexts.home.link}>
              <Icon alt={iconsPaths.logo.alt} src={iconsPaths.logo.path} />
            </LogoLink>
            <LabelsBlock>
              {appTexts.header.map(item => (
                <MyLink key={item.label + item.link} to={item.link}>
                  {item.label}
                </MyLink>
              ))}
              {appTexts.headerList.map(item => (
                <DropList
                  key={item.listName}
                  bottomItem={item.bottomItem}
                  links={item.links}
                  listName={item.listName}
                />
              ))}
            </LabelsBlock>
          </LeftBlock>
          <RightBlock visible={localStorage.getItem("token") != null}>
            <NotificationBlock />
            <AvatarPic src={iconsPaths.avatar} alt="" />
            <LogOutIcon
              onClick={() => visibleLogOut(true)}
              src={iconsPaths.iconLogout}
              alt=""
            />
          </RightBlock>
        </NavBar>
      </MainContainer>
      <LogOutModal
        open={logOutModal}
        customStyles={defaultModal}
        acceptButtonText="Logout"
        onClose={() => visibleLogOut(false)}
        title="Do you really want out?"
        description="After leaving your profile you will not be able to access its functionality."
        onAccept={logOutHandler}
      />
    </HeaderContainer>
  );
};

const MainContainer = styled(BootstrapContainer)`
  width: 100%;
`;

const HeaderContainer = styled.header`
  height: 92px;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;

  border-bottom: 1.5px solid #dfe0eb;
`;

const NavBar = styled.nav`
  padding: 24px 0;
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const LogOutIcon = styled.img`
  cursor: pointer;
`;

const AvatarPic = styled.img`
  height: 100%;
  object-fit: contain;
  border: 1.5px solid #cbd1dd;
  padding: 3px;
  border-radius: 50%;
  box-sizing: border-box;
`;

const LabelsBlock = styled.div`
  display: flex;
  align-items: center;
  padding-left: 55px;

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
`;

const LogoLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #26363e;
  padding-right: 35px;
  text-decoration: none !important;

  @media screen and (max-width: 991px) {
    padding-right: 20px;
  }
`;

const MyLink = styled(LogoLink)`
  opacity: 0.7;

  :hover {
    color: #26363e;
    opacity: 1;
  }
`;

const LeftBlock = styled.div`
  display: flex;
`;

const RightBlock = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? "flex" : "none")};
  align-items: center;

  & > :nth-child(2) {
    margin-right: 11px;
  }
`;
