import { iconsPaths } from "./paths/Icons";

export interface ILink {
  label: string;
  link: string;
  icoPath?: string;
}

export interface IDropList {
  listName: string;
  links: Array<ILink>;
  bottomItem: ILink;
}

interface IAppTexts {
  home: ILink;
  header: ILink[];
  headerMobile: ILink[];
  headerList: [IDropList];
}

export const appTexts: IAppTexts = {
  home: { label: "Uconsent", link: "/" },
  header: [
    { label: "Events", link: "/" },
    { label: "Contacts", link: "/contacts" },
    { label: "Agreements", link: "/agreements" },
    { label: "Terms & Conditions", link: "/terms-and-conditions" },
  ],
  headerMobile: [
    { label: "Events", link: "/events", icoPath: iconsPaths.iconEvents },
    { label: "Contacts", link: "/contacts", icoPath: iconsPaths.iconContacts },
    {
      label: "Agreements",
      link: "/agreements",
      icoPath: iconsPaths.iconAgreements
    },
    { label: "Terms & Conditions", link: "/agreements" },
    { label: "Help", link: "/help", icoPath: iconsPaths.iconHelp }
  ],
  headerList: [
    {
      listName: "Help",
      links: [
        {
          label: "How to create an event?",
          link: "#"
        },
        {
          label: "How to create an agreement?",
          link: "#"
        },
        {
          label: "How to create an invitation?",
          link: "#"
        }
      ],
      bottomItem: {
        label: "How to create an invitation?",
        link: "#"
      }
    }
  ]
};
