import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import { FormikProps } from "formik";
import {
  ButtonBlue,
  ButtonWhiteBorderBlue,
  InputPrimary
} from "../../../ui/MyComponents";
import { CheckBox } from "../../../ui/CheckBox";
import { ActiveCheckBox } from "../../../ui/ActiveCheckBox";
import { ILoginFields } from "./Login";
import { makeStyles } from "@material-ui/core/styles";

export const LoginForm = (props: FormikProps<ILoginFields>) => {
  const {
    values: { login, password },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    handleSubmit
  } = props;

  const change = (name: string, e: { persist: () => void }) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const classes = {
    ButtonWhiteBorderBlue: makeStyles(ButtonWhiteBorderBlue)(),
    buttonBlue: makeStyles(ButtonBlue)(),
    inputPrimary: makeStyles(InputPrimary)()
  };

  return (
    <MainBlock>
      <MyForm onSubmit={handleSubmit}>
        <Title>Log in with email</Title>
        <TextFieldBlock>
          <TextField
            classes={classes.inputPrimary}
            size="medium"
            id="login"
            name="login"
            placeholder="Email Address"
            variant="outlined"
            fullWidth
            helperText={touched.login ? errors.login : ""}
            error={touched.login && Boolean(errors.login)}
            value={login}
            onChange={e => change("login", e)}
          />
        </TextFieldBlock>
        <TextFieldBlock>
          <TextField
            classes={classes.inputPrimary}
            id="password"
            name="password"
            placeholder="Password"
            variant="outlined"
            fullWidth
            type="password"
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            value={password}
            onChange={e => change("password", e)}
          />
        </TextFieldBlock>
        <CheckBoxBlock>
          <FormControlLabel
            control={
              <Checkbox icon={<CheckBox />} checkedIcon={<ActiveCheckBox />} />
            }
            label="Keep it logged in"
            labelPlacement="end"
          />
          <SimpleLabel>Forgot your password ?</SimpleLabel>
        </CheckBoxBlock>
        <ButtonsBlock>
          <Button
            classes={classes.ButtonWhiteBorderBlue}
            type="submit"
            variant="contained"
            disabled={!isValid}
          >
            Log in
          </Button>
          <Button
            classes={classes.buttonBlue}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            DocuSign Log in
          </Button>
        </ButtonsBlock>
      </MyForm>
    </MainBlock>
  );
};

const Title = styled.h2`
  margin-bottom: 25px;
`;

const TextFieldBlock = styled.div`
  margin-bottom: 20px;
`;

const MainBlock = styled.div`
  width: 325px;

  @media screen and (max-width: 374px) {
    width: 100%;
  }
`;

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;

  > :first-child {
    width: 140px;
    margin-right: 15px;
  }

  > :nth-child(2) {
    width: 170px;
  }
`;

const MyForm = styled.form`
  > :nth-child(3) {
    margin-bottom: 15px;
  }
`;

const SimpleLabel = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #dd6970;

  @media screen and (max-width: 374px) {
    white-space: nowrap;
    font-size: 12px;
  }
`;

const CheckBoxBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
`;
