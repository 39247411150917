import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      outlined: {
        transform: "translate(20px, 17px) scale(1)",
        "&$shrink": {
          transform: "translate(17px, -6px) scale(0.75)"
        }
      }
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderWidth: 1
          }
        }
      }
    },
    MuiInputBase: {
      root: {
        height: 50,
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#26363E",
      }
    },
    MuiOutlinedInput: {
      root: {
        padding: "16px 20px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px !important",
          borderColor: "rgba(0, 0, 0, 0.23) !important"
        }
      },
      multiline: {
        padding: "16px 19px",
        height: "auto"
      },
      input: {
        padding: "0"
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "12px",
        lineHeight: "15px",
        "&$error": {
          color: "#A6A9AF"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: "Muli",
        fontSize: "11px",
        lineHeight: "14px",
        fontWeight: 600,
        marginTop: 5
      }
    },
    MuiTypography: {
      root: {
        fontWeight: 600,
        fontSize: "13px",
        lineHeight: "16px"
      },
      body1: {
        fontWeight: 600,
        fontSize: "13px",
        lineHeight: "16px",
        "@media (max-width:374px)": {
          fontSize: "12px",
          whiteSpace: "nowrap"
        }
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#FFFFFF"
        }
      },
      iconOutlined: {
        width: 20,
        height: 20,
        right: 15
      },
      icon: {
        top: "calc(50% - 10px)"
      }
    }
  },
  palette: {
    primary: {
      main: "#2E71D8"
    },
    secondary: {
      main: "#E33E7F"
    },
    action: {
      disabledBackground: "#7FB1FF",
      disabled: "#FFFFFF"
    },
    error: {
      main: "#DD6970"
    }
  },
  typography: {
    fontFamily: [
      "Muli",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue"
    ].join(","),
    button: {
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "18px",
      textAlign: "center",
      textTransform: "unset"
    }
  }
});
