import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/bootstrap.min.css";
import "./assets/styles/clearfix.scss";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { SessionContextProvider } from "./contexts/SessionContext";
import { EventCreateContextProvider } from "./contexts/EventCreateContext";
import { ContactsContextProvider } from "./contexts/ContactsContext";
import { SelectedContactsContextProvider } from "./contexts/SelectedContactsContext";
import { ModalContextProvider } from "./contexts/ModalContext";

ReactDOM.render(
  <SessionContextProvider>
    <EventCreateContextProvider>
      <ContactsContextProvider>
        <SelectedContactsContextProvider>
          <ModalContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ModalContextProvider>
        </SelectedContactsContextProvider>
      </ContactsContextProvider>
    </EventCreateContextProvider>
  </SessionContextProvider>,
  document.getElementById("root")
);
