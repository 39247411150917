import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BootstrapContainer } from "../../ui/BootstrapContainer";
import { iconsPaths } from "../../core/paths/Icons";
import { MobileMenu } from "./MobileMenu";
import { appTexts } from "../../core/Text";
import { NotificationMobileBlock } from "./NotificationMobileBlock";

export const HeaderMobile = () => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayNotifications, setDisplayNotifications] = useState(false);

  const notificationsChangeDisplay = () => {
    setDisplayMenu(false);
    setTimeout(() => setDisplayNotifications(!displayNotifications), 0.1);
  };

  const menuChangeDisplay = () => {
    setDisplayNotifications(false);
    setTimeout(() => setDisplayMenu(!displayMenu), 0.1);
  };

  return (
    <HeaderContainer>
      <BootstrapContainer paddingMobile>
        <NavBar>
          <LeftBlock>
            <MyLink to={appTexts.home.link}>
              <img src={iconsPaths.iconLogo} alt="" />
            </MyLink>
          </LeftBlock>

          <RightBlock visible={localStorage.getItem("token") != null}>
            <NotificationMobileBlock
              isVisible={displayNotifications}
              notificationsChangeDisplay={notificationsChangeDisplay}
            />
            <AvatarPic src={iconsPaths.avatar} alt="" />
            <MobileMenu
              isVisible={displayMenu}
              menuChangeDisplay={menuChangeDisplay}
            />
          </RightBlock>
        </NavBar>
      </BootstrapContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  height: 84px;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
`;

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
`;
const LeftBlock = styled.div``;
const RightBlock = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? "flex" : "none")};
  align-items: center;
`;

const MyLink = styled(Link)``;

const AvatarPic = styled.img`
  width: 44px;
  height: 44px;
  border: 1.5px solid #cbd1dd;
  padding: 3px;
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: none;
  margin-right: 20px;
`;
