import { BaseRequest } from "../BaseRequest";
import { ITemplateAgreement } from "../dto/TemplateAgreement";

export class TemplateAgreementRequests extends BaseRequest {
  get(): Promise<ITemplateAgreement[]> {
    return this.fetch(`/api/TemplateAgreement/Get`, { method: "GET" })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }
}
