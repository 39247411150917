import * as Yup from "yup";

export const validationSchemaSignUp = Yup.object({
  login: Yup.string()
    .email("Enter a valid email")
    .matches(
      /^(([^<>()[\]\\.,;:А-яёЁ\s@/"]{2,}(\.[^<>()[\]\\.,;:\s@"]{2,})*)|(".+")|([^<>()[\]\\.,;:А-яёЁ\s@/"]+\.[^<>()[\]\\.,;:А-яёЁ\s@/"]+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/
    )
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must contain 6 characters")
    .max(25, "Max 25 characters")
    .required("Password is Required!"),
  confirmPassword: Yup.string()
    .required("This field is required")
    .when("password", {
      is: val => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    })
});
