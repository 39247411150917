import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { Link } from "react-router-dom";
import { iconsPaths } from "../../core/paths/Icons";
import { BootstrapContainer } from "../../ui/BootstrapContainer";
import { appTexts } from "../../core/Text";
import iconPlus from "../../assets/icons/plus.svg";
import { ButtonPrimary, ButtonWhiteBlueIcon } from "../../ui/MyComponents";
import { LogOutModal } from "../../ui/modal/LogOutModal";
import { useSessionContext } from "../../contexts/SessionContext";
import { ButtonIcon } from "../../ui/ButtonIcon";
import { makeStyles } from "@material-ui/core/styles";
import {defaultModal} from "../../ui/styles/defaultModal";

interface IProps {
  isVisible: boolean;
  menuChangeDisplay: () => void;
}

export const MobileMenu: FC<IProps> = ({ isVisible, menuChangeDisplay }) => {
  const target = useRef<HTMLDivElement>(null);
  const [sessionContext, updateSessionContext] = useSessionContext();

  const classes = {
    ButtonWhiteBlueIcon: makeStyles(ButtonWhiteBlueIcon)(),
    ButtonPrimary: makeStyles(ButtonPrimary)()
  };

  const [logOutModal, setLogOutModal] = useState(false);
  const visibleLogOut = (val = true) => {
    setLogOutModal(val);
  };

  const logOutHandler = () => {
    localStorage.clear();
    visibleLogOut(false);
    updateSessionContext({ ...sessionContext, isAuthenticated: false });
  };

  useEffect(() => {
    if (target.current && (isVisible || logOutModal)) {
      disableBodyScroll(target.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [isVisible, target, logOutModal]);

  return (
    <MainContainer>
      {isVisible ? (
        <MenuChangeIcon
          src={iconsPaths.iconClose}
          alt=""
          onClick={menuChangeDisplay}
        />
      ) : (
        <MenuChangeIcon
          src={iconsPaths.iconLines}
          alt=""
          onClick={menuChangeDisplay}
        />
      )}

      <MainBlock open={isVisible} ref={target}>
        <BootstrapContainer paddingMobile>
          <Content>
            <LinkBlock>
              {appTexts.headerMobile.map(item => (
                <LinkLine key={item.label + item.link}>
                  <LinesIcon src={item.icoPath} alt={item.label} />
                  <LinkLabel to={item.link}>{item.label}</LinkLabel>
                </LinkLine>
              ))}

              <LinkLine
                onClick={() => {
                  menuChangeDisplay();
                  visibleLogOut(true);
                }}
              >
                <LinesIcon src={iconsPaths.iconLogoutMobile} alt="" />
                <LinkLabel to="#">Log Out</LinkLabel>
              </LinkLine>
            </LinkBlock>
            <ButtonBlock>
              <WhiteButtonBlock>
                <Button
                  classes={classes.ButtonWhiteBlueIcon}
                  fullWidth
                  startIcon={<ButtonIcon src={iconPlus} />}
                  variant="outlined"
                >
                  Create a New Invitation
                </Button>
              </WhiteButtonBlock>
              <Button
                classes={classes.ButtonPrimary}
                fullWidth
                variant="contained"
                color="primary"
              >
                Create a New Event
              </Button>
            </ButtonBlock>
          </Content>
        </BootstrapContainer>
      </MainBlock>
      <LogOutModal
        customStyles={defaultModal}
        open={logOutModal}
        acceptButtonText="Logout"
        onClose={() => visibleLogOut(false)}
        title="Do you really want out?"
        description="After leaving your profile you will not be able to access its functionality."
        onAccept={logOutHandler}
      />
    </MainContainer>
  );
};

const LinesIcon = styled.img`
  cursor: pointer;
`;

const MenuChangeIcon = styled.img`
  cursor: pointer;
  width: 22px;
  height: 16px;
`;

const MainContainer = styled.div``;

const MainBlock = styled.div<{ open: boolean }>`
  display: ${props => (props.open ? "block" : "none")};
  width: 100%;
  position: absolute;
  top: 84px;
  height: calc(100vh - 84px);
  left: 0;
  background-color: #ffffff;
  z-index: 99;
`;
const LinkBlock = styled.div`
  padding-left: 5px;
  padding-top: 60px;
  div:not(:last-child) {
    margin-bottom: 26px;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 40px;
`;
const LinkLine = styled.div`
  display: flex;
  img {
    padding-right: 26px;
  }
`;
const LinkLabel = styled(Link)`
  color: #252733;
  text-decoration: none !important;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;

  &:hover {
    color: #252733;
  }
`;

const Content = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-flow: column;
`;

const WhiteButtonBlock = styled.div`
  margin-bottom: 15px;
`;
