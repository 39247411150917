import { Button, Fade } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";
import { iconsPaths } from "../../core/paths/Icons";
import { ButtonPrimary, ButtonWhite } from "../MyComponents";
import { IStyledModalProps, StyledModal } from "./StyledModal";
import { makeStyles } from "@material-ui/core/styles";
import { defaultModal } from "../styles/defaultModal";

export interface IConfirmationModalProps extends IStyledModalProps {
  onAccept?: () => void;
  acceptButtonText?: string;
  cancelButtonText?: string;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
  title,
  children,
  open,
  onClose,
  onAccept,
  acceptButtonText,
  cancelButtonText,
  ...rest
}) => {
  const classes = {
    ButtonWhiteBlueBordered: makeStyles(ButtonWhite)(),
    primaryButton: makeStyles(ButtonPrimary)()
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      {...rest}
      customStyles={defaultModal}
    >
      <Fade in={open}>
        <MainContainer>
          <HeaderBlock>
            <HeaderLabel>{title}</HeaderLabel>
            <IconCross alt="" src={iconsPaths.iconCross} onClick={onClose} />
          </HeaderBlock>
          <BodyBlock>{children}</BodyBlock>
          <FooterBlock>
            <ButtonBlock>
              <Button
                classes={classes.ButtonWhiteBlueBordered}
                fullWidth
                variant="contained"
                onClick={onClose}
              >
                {cancelButtonText || "Cancel"}
              </Button>
            </ButtonBlock>

            <ButtonBlock>
              <Button
                classes={classes.primaryButton}
                fullWidth
                variant="contained"
                color="primary"
                onClick={onAccept}
              >
                {acceptButtonText || "Yes, delete"}
              </Button>
            </ButtonBlock>
          </FooterBlock>
        </MainContainer>
      </Fade>
    </StyledModal>
  );
};

const MainContainer = styled.div`
  width: 410px;
  background: #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 30px;
  outline: 0;

  @media screen and (max-width: 767px) {
    padding: 30px 24px;
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const HeaderLabel = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #26363e;
`;

const IconCross = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const BodyBlock = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 23px;
    > :nth-child(3) {
      display: none;
    }
  }
`;

const ButtonBlock = styled.div`
  width: 165px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const FooterBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-flow: column-reverse;

    > :nth-child(2) {
      margin-bottom: 15px;
    }
  }
`;
