import { BaseRequest } from "../BaseRequest";
import { IEventType } from "../../components/event/create/default/CreateEventPage";

export class EventTypeRequests extends BaseRequest {
  get(): Promise<IEventType[]> {
    return this.fetch("/api/EventType/Get", {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }
}
