import React, { createRef, FC, useEffect } from "react";
import styled from "styled-components";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { iconsPaths } from "../../core/paths/Icons";
import { BootstrapContainer } from "../../ui/BootstrapContainer";
import { FakeNotifications } from "../../api/FakeData";
import { timeDifferent } from "../../core/utils/TimeDifferent";

interface IProps {
  isVisible: boolean;
  notificationsChangeDisplay: () => void;
}

export const NotificationMobileBlock: FC<IProps> = ({
  isVisible,
  notificationsChangeDisplay
}) => {
  const myRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (isVisible && myRef.current) {
      disableBodyScroll(myRef.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [isVisible, myRef]);

  return (
    <MainContainer>
      {isVisible ? (
        <>
          <Icon
            src={iconsPaths.iconNotificationActive}
            alt=""
            onClick={notificationsChangeDisplay}
          />
          <AbsoluteBlock ref={myRef}>
            <HeaderBock>
              <BootstrapContainer paddingMobile>
                <Title>Your Notification</Title>
              </BootstrapContainer>
            </HeaderBock>
            <BodyBlock>
              <BootstrapContainer paddingMobile>
                <NotificationBody>
                  {FakeNotifications.map(notification => (
                    <ItemBlock key={notification.id}>
                      <ItemImage
                        src={notification.image}
                        alt={notification.name}
                      />
                      <ItemNameBlock>
                        <ItemTopBlock>
                          <ItemName>{notification.name}</ItemName>
                          <DotBlock>
                            <GrayDot src={iconsPaths.iconDot} alt="" />
                            <GrayText>
                              {timeDifferent(notification.date)}
                            </GrayText>
                          </DotBlock>
                        </ItemTopBlock>
                        <GrayText>{notification.description}</GrayText>
                      </ItemNameBlock>
                    </ItemBlock>
                  ))}
                </NotificationBody>
              </BootstrapContainer>
            </BodyBlock>
          </AbsoluteBlock>
        </>
      ) : (
        <Icon
          alt=""
          src={iconsPaths.iconNotificationMobile}
          onClick={notificationsChangeDisplay}
        />
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 21px;
  height: 20px;
  object-fit: cover;
  margin-right: 18px;
  cursor: pointer;
`;

const AbsoluteBlock = styled.div`
  display: block;
  width: 100%;
  height: calc(100vh - 84px);
  position: absolute;
  top: 84px;
  right: 0;
  z-index: 3;
  background-color: #ffffff;
  overflow-y: auto;
`;

const Title = styled.h1`
  margin: 25px 0;
`;

const HeaderBock = styled.div`
  border-bottom: 1px solid #dfe0eb;
  display: flex;
`;

const BodyBlock = styled.div``;

const ItemBlock = styled.div`
  display: flex;
  padding-top: 20px;
`;
const ItemImage = styled.img`
  width: 44px;
  height: 44px;
  margin-right: 15px;
  border-radius: 50%;
`;

const ItemNameBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const ItemTopBlock = styled.div`
  display: flex;
`;

const ItemName = styled.span`
  max-width: 180px;
  height: min-content;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252733;
`;

const GrayText = styled.span`
  white-space: nowrap;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #a6a9af;
`;

const GrayDot = styled.img`
  margin: 0 7px;
`;

const DotBlock = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationBody = styled.div`
  width: 100%;

  > :first-child {
    padding-top: 25px;
  }
  > :last-child {
    padding-bottom: 20px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(194, 194, 194, 0.2);
    border-radius: 5px;
    scrollbar-color: rgba(194, 194, 194, 0.2);
  }
`;
