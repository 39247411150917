import React, { createContext, FC, useState } from "react";
import { ModalTypesEnum } from "../components/ModalShow";

export interface IModal {
  type: ModalTypesEnum;
  id: number;
  title: string;
  description: string;
  closeText?: string;
  acceptText?: string;
}

export const ModalContext = createContext<[IModal, (type: IModal) => void]>([
  {} as IModal,
  () => {}
]);

export const ModalContextProvider: FC = props => {
  const { children } = props;
  const [modalContext, setModalContext] = useState<IModal>({} as IModal);

  return (
    <ModalContext.Provider value={[modalContext, setModalContext]}>
      {children}
    </ModalContext.Provider>
  );
};
