import * as Yup from "yup";

export const validationSchemaLogin = Yup.object().shape({
  login: Yup.string()
    .email("Only letters, no special characters")
    .matches(
      /^(([^<>()[\]\\.,;:А-яёЁ\s@/"]{2,}(\.[^<>()[\]\\.,;:\s@"]{2,})*)|(".+")|([^<>()[\]\\.,;:А-яёЁ\s@/"]+\.[^<>()[\]\\.,;:А-яёЁ\s@/"]+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/
    )
    .required("Only letters, no special characters"),
  password: Yup.string()
    .min(6, "Password must contain 6 characters")
    .required("Enter your password")
});
