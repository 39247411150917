import { INotification } from "../components/header/NotificationBlock";
import { IContact } from "./dto/Contact";
import { iconsPaths } from "../core/paths/Icons";

export const FakeNotifications: INotification[] = [
  {
    id: 0,
    name: "Joseph Stevenson",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: Date.now().toString()
  },
  {
    id: 1,
    name: "Gilbert Wells",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1582812390313"
  },
  {
    id: 2,
    name: "James Baldwin",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1525955100000"
  },
  {
    id: 3,
    name: "James Baldwin",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1525955100000"
  },
  {
    id: 4,
    name: "James Baldwin",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1525955100000"
  },
  {
    id: 5,
    name: "James Baldwin",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1525955100000"
  },
  {
    id: 6,
    name: "James Baldwin Baldwin Ba",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1525955100000"
  },
  {
    id: 7,
    name: "James Baldwin Baldwin Ba",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1525955100000"
  },
  {
    id: 8,
    name: "James Baldwin Baldwin Ba",
    description: "has approved agreement",
    image: iconsPaths.contactAvatar,
    date: "1525955100000"
  }
];

export const FakeContacts: IContact[] = [
  {
    id: 1,
    name:
      "AlexanderAlexanderAlexanderAlexanderAlexanderAlexanderAlexanderAlexander StrizhovStrizhovStrizhov",
    email: "alexxst.st@yandexyandexyandyandexexyandex.ru",
    imagePath:
      "https://sun1-47.userapi.com/vsVD6uA_3Zawyo_AcGCOXj4kqVa23_3tWTF7rg/VfbUJtGzh88.jpg?ava=1"
  },
  {
    id: 2,
    name: "Alexander Strizhov",
    email: "alexxst.st@yandex.ru",
    imagePath:
      "https://sun1-47.userapi.com/vsVD6uA_3Zawyo_AcGCOXj4kqVa23_3tWTF7rg/VfbUJtGzh88.jpg?ava=1"
  },
  {
    id: 3,
    name: "Alexander Strizhov",
    email: "alexxst.st@yandex.ru",
    imagePath:
      "https://sun1-47.userapi.com/vsVD6uA_3Zawyo_AcGCOXj4kqVa23_3tWTF7rg/VfbUJtGzh88.jpg?ava=1"
  },
  {
    id: 4,
    name: "Alexander Strizhov",
    email: "alexxst.st@yandex.ru",
    imagePath:
      "https://sun1-47.userapi.com/vsVD6uA_3Zawyo_AcGCOXj4kqVa23_3tWTF7rg/VfbUJtGzh88.jpg?ava=1"
  }
];
