import { BaseRequest } from "../BaseRequest";
import { IIntegrationStatus } from "../dto/IntegrationStatus";

export class SignRequests extends BaseRequest {
  checkIntegrationStatus(): Promise<IIntegrationStatus> {
    return this.fetch("/api/Sign/CheckIntegrationStatus", {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  confirmAgreement(inviteId: string | number): Promise<void> {
    return this.fetch(`/api/Sign/ConfirmAgreement?inviteId=${inviteId}`, {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }
}
