import React from "react";
import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";
import { FormikProps } from "formik";
import { ButtonBlue, InputPrimary } from "../../../ui/MyComponents";
import { ISignUpFields } from "./SignUp";
import { makeStyles } from "@material-ui/core/styles";

export const SignUpForm = (props: FormikProps<ISignUpFields>) => {
  const classes = {
    ButtonBlue: makeStyles(ButtonBlue)(),
    InputPrimary: makeStyles(InputPrimary)()
  };

  const {
    values: { login, password, confirmPassword },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    handleSubmit
  } = props;

  const change = (name: string, e: { persist: () => void }) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <MainBlock>
      <MyForm onSubmit={handleSubmit}>
        <Title>Sign up</Title>
        <TextFieldBlock>
          <TextField
            classes={classes.InputPrimary}
            size="medium"
            name="login"
            placeholder="Email Address"
            variant="outlined"
            fullWidth
            helperText={touched.login ? errors.login : ""}
            error={touched.login && Boolean(errors.login)}
            value={login}
            onChange={e => change("login", e)}
          />
        </TextFieldBlock>
        <TextFieldBlock>
          <TextField
            classes={classes.InputPrimary}
            name="password"
            placeholder="Password"
            variant="outlined"
            fullWidth
            type="password"
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            value={password}
            onChange={e => change("password", e)}
          />
        </TextFieldBlock>
        <TextFieldBlock>
          <TextField
            classes={classes.InputPrimary}
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm password"
            variant="outlined"
            fullWidth
            type="password"
            helperText={touched.confirmPassword ? errors.confirmPassword : ""}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            value={confirmPassword}
            onChange={e => change("confirmPassword", e)}
          />
        </TextFieldBlock>
        <ButtonsBlock>
          <Button
            classes={classes.ButtonBlue}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Sign up
          </Button>
        </ButtonsBlock>
      </MyForm>
    </MainBlock>
  );
};

const Title = styled.h2`
  margin-bottom: 25px;
`;

const TextFieldBlock = styled.div`
  margin-bottom: 20px;
`;

const MainBlock = styled.div`
  width: 325px;
  @media screen and (max-width: 374px) {
    width: 100%;
  }
`;

const ButtonsBlock = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const MyForm = styled.form``;
