import {createStyles, makeStyles, Theme} from "@material-ui/core";

export const defaultModal = makeStyles((_theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(48,46,46,0.3)",
      "@media screen and (max-width:767px)": {
        alignItems: "flex-end"
      }
    }
  })
);