import { BaseRequest } from "../BaseRequest";
import { IVenue } from "../dto/Venue";
import { IAddressFullSearch } from "../dto/AddressFullSearch";

export class GeoRequests extends BaseRequest {
  venues(): Promise<IVenue[]> {
    return this.fetch("/api/Geo/Venues", { method: "GET" })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }

  addressFullSearch(search: string): Promise<IAddressFullSearch[]> {
    return this.fetch(`/api/Geo/FullSearchAddress?pattern=${search}`, {
      method: "GET"
    })
      .then(response => response.json())
      .catch(BaseRequest.handleError);
  }
}
